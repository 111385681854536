import React, { Component } from 'react';
import {
	TextField,
	Button,
	FormLayout,
} from '@shopify/polaris';

export default class WhiteListItem extends Component {
	constructor(props) {
		super(props);  

		this.state = {
			inputError: false
		};
	}

	isDomain(str) { 
		var re = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/); 
		return str.match(re);
	} 

	componentDidUpdate(prevProps) {
		if (this.props.data !== prevProps.data) {
			if (this.isDomain(this.props.data)) {
				this.setState({ inputError: false});
			} else {
				this.setState({ inputError: 'Please enter a valid domain.'});
			}
		}
	}

	render() {
		return (
			<FormLayout>
				<FormLayout.Group condensed>
					<TextField
						label="Whitelist URLs"
						labelHidden
						id={this.props.id}
						value={this.props.data}
						placeholder="e.g. www.anotherwebsite.com"
						onChange={this.props.handleWhilteListChange}
						error={this.state.inputError}
					/>
					<Button
						id={this.props.id}
						icon="delete"
						onClick={this.props.handleWhilteListDelete}
					>
					</Button>
				</FormLayout.Group>
			</FormLayout>
		);
	}

}
