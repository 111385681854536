// @flow

// Reducer
const INITIAL_STATE = {
  isAuthorized: null
};

export default (state=INITIAL_STATE, action: Action): Group[] => {
  //console.log(action);
  switch (action.type) {
    case 'USER_AUTH_SUCCESS':
      return {
        ...state,
        isAuthorized: true
      };
    case 'USER_AUTH_FAILURE':
      return {
        ...state,
        isAuthorized: false
      };
    default:
      return state;
  }
};
