// @flow
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import user from './user';
import settings from './settings';

export default (history) => combineReducers({
  router: connectRouter(history),
  user,
  settings,
});
