// @flow
import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import Help from './containers/Help';
import Home from './containers/Home';
import Error from './containers/Error';
import Rules from './containers/Rules';

const App = () =>
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/help" component={Help} />
    <Route exact path="/error" component={Error} />
    <Route exact path="/rules/:id" component={Rules} />
    <Route exact path="/rules" component={Rules} />
    <Redirect to="/" />
  </Switch>

export default App;
