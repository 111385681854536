// @flow
import React from 'react';
import { Card, Page } from '@shopify/polaris';

const Help = ({ history }) =>
	<Page
		title="Help"
		breadcrumbs={[{content: 'Back', onAction: () => history.goBack()}]}
		separator
	>
		<Card title="FAQ">
			<Card.Section title="How is this app works?">
				<p>First, you need to connet the prodcuts manually or choose to connect by SKU automatically. When customer placed an order, we will deduce the quantity from all the connected products/variants.</p>
			</Card.Section>
			<Card.Section title="Why the app fail to synchroize new products even I use the same SKU?">
				<p>In 'auto-connect SKU' mode: Everytime you add a new prodcut or change the SKU, it is reuqired to refresh SKU in this app. Click 'Refresh SKU'.</p>
				<p>In 'manual' mode: You need to add product to the specific grouop manually. </p>
			</Card.Section>
			<Card.Section title="Why the inventory does not update after I change product/variant quantity in admin page?">
				<p>It is expected, updating via admin page will not synchroize to other product. Instead, you can make use of the 'Edit Inventory' feature to update all conected inventory in one go.</p>
			</Card.Section>
		</Card>
		<Card
			title="Contact us"
			sectioned
		>
			<p>If you have any query, please contact us at <a href="mailto:support@inscoder.com" target="_top">support@inscoder.com</a></p>
		</Card>
	</Page>;

export default Help;
