// @flow
//import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  FormLayout,
  TextField,
} from '@shopify/polaris';

import { updateData } from '../actions/action_settings';

export class DefaultURLCard extends Component {
  constructor(props) {
    super(props);  

    this.state = {
      inputError: false
    }
  }

  isURL(str) {
    var pattern = new RegExp('^(https:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name and extension
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?'+ // port
    '(\\/[-a-z\\d%@_.~+&:]*)*'+ // path
    '(\\?[;&a-z\\d%@_.,~+&:=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return pattern.test(str);
  }

  handleDefaultURL = (value) => {
    const { updateData } = this.props;
    let { settings } = this.props;
    settings.data.rule.default_redirect_url = value;
    
    var inputError;
    if (this.isURL(value)) {
      inputError = null;
    } else {
      inputError = "Please enter a valid URL.";
    }
    this.setState({inputError: inputError});
    updateData(settings.data);
  };

  render() {
    const { settings } = this.props;
    if (!settings.data) {
      return null;
    }

    return (
      <Card
        sectioned
        title="Step 3: Default website"
      >
        <FormLayout>
          <TextField
            label="If none of the country is matched, we will send your customer here."
            placeholder="e.g. https://www.example.com"
            value={settings.data.rule.default_redirect_url}
            onChange={this.handleDefaultURL}
            error={this.state.inputError}
          />
        </FormLayout>
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  const { settings } = state;

  return {
    settings: settings,
  };
};

const dispatchProps = {
  updateData,
};

export default connect(mapStateToProps, dispatchProps)(DefaultURLCard);
