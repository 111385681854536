import React, { Component } from 'react';
import {
  Autocomplete,
  Icon,
} from '@shopify/polaris';
import countryList from '../data/countryList.json';

export default class AutocompleteCountry extends Component {

    constructor(props) {
      super(props);
    
      this.options = countryList;

      this.state = {
        selected: [this.props.selectedText],
        inputText: [this.props.selectedText],
        options: countryList,
        loading: false,
      };
  
    }
  
    render() {
      return (
        <div>
          <Autocomplete
            options={this.state.options}
            selected={this.state.selected}
            onSelect={this.updateSelection}
            loading={this.state.loading}
            textField={
              <Autocomplete.TextField
                onChange={this.updateText}
                label="Country"
                value={this.state.inputText}
                prefix={<Icon source="search" color="inkLighter" />}
                placeholder="Search"
              />
            }
          />
        </div>
      );
    }
  
    updateText = (newValue) => {
      this.setState({inputText: newValue});
      this.filterAndUpdateOptions(newValue);
    };
  
    filterAndUpdateOptions = (inputString) => {
      if (!this.state.loading) {
        this.setState({loading: true});
      }
  
      setTimeout(() => {
        if (inputString === '') {
          this.setState({
            options: this.options,
            loading: false,
          });
          return;
        }
        const filterRegex = new RegExp(inputString, 'i');
        const resultOptions = this.options.filter((option) =>
          option.label.match(filterRegex),
        );
        this.setState({
          options: resultOptions,
          loading: false,
        });
      }, 300);
    };
  
    updateSelection = (updatedSelection) => {
      //updatedSelection is the value of the options (i.e. countryCode)      
      const selectedText = updatedSelection.map((selectedItem) => {
        const matchedOption = this.options.filter((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption[0] && matchedOption[0].label;
      });      
      this.setState({selected: selectedText, inputText: selectedText});
      
      //Update the selected option back to the parent
      this.props.onSelected(updatedSelection[0], selectedText[0]);
    };
  }