// @flow
//import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import URLSearchParams from 'url-search-params';
import {
  Page,
  Frame,
  ContextualSaveBar,
  ProgressBar,
  SkeletonPage,
  Layout,
  Card,
  SkeletonBodyText,
} from '@shopify/polaris';

import WhiteListCard from './WhiteListCard';
import DefaultURLCard from './DefaultURLCard';
import GreetingsCard from './GreetingsCard';
import CountryRuleCard from './CountryRuleCard';

import { getSettings, postSettings, updateData } from '../actions/action_settings';

//
export class HomeComponent extends Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(this.props.location.search);
    const token = params.get('token');
    const shop = params.get('shop');
    const engine = params.get('engine');
    if (token) sessionStorage.setItem('token', token);
    if (shop) sessionStorage.setItem('shop', shop);
    if (engine) sessionStorage.setItem('engine', engine);

  }

  componentDidMount() {
    //console.log("HomeComponent: componentDidMount");
    const { getSettings } = this.props;

    //save the token in sessionStorage
    getSettings();

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //console.log('HomeComponent: componentDidUpdate');
    const { user } = this.props;
    if (user.isAuthorized === false ) {
      this.handleError();
    }
  }

  handleSave = () => {
    const { postSettings } = this.props;
    let { settings } = this.props;    
    postSettings(settings.data.rule);  
  }

  handleDiscard = () => {
    this.props.history.go(0);
  } 

  handleLink = (path) => {
    this.props.history.push(`/${path}`);
  };

  render() {
    //console.log('HomeComponent: redner');
    //console.log(this.props);
    //console.log(this.state);
    const { settings } = this.props;
    if (!settings.data) {
      return (
        <SkeletonPage title="Geo-IP Redirect Manager" primaryAction secondaryActions={1}>
          <Layout>
            <Layout.Section>
              <Card sectioned>
                <SkeletonBodyText />
              </Card>
              <Card sectioned title="Step 1: Greetings">
                <SkeletonBodyText />
              </Card>
              <Card sectioned title="Step 2: Country rules">
                <SkeletonBodyText />
              </Card>
              <Card sectioned title="Step 3: Default website">
                <SkeletonBodyText />
              </Card>
              <Card sectioned title="Step 4: (Optional) Whitelist">
                <SkeletonBodyText />
              </Card>
            </Layout.Section>
          </Layout>
        </SkeletonPage>
      );
    }
  
    const contextualSaveBarMarkup = settings.isDirty ? (
      <ContextualSaveBar
        message="Unsaved changes"
        saveAction={{
          onAction: this.handleSave,
        }}
        discardAction={{
          onAction: this.handleDiscard,
        }}
      />
    ) : null;

    const progressBarMarkup = settings.isUpdating ? (
      <ProgressBar progress={90} size="small" />
    ) : null;

    return (
      <Frame>
        {contextualSaveBarMarkup}
        {progressBarMarkup}
        <Page
          title="Geo-IP Redirect Manager"
          secondaryActions={[
            {
              content: 'Help',
              onAction: this.handleLink.bind(this,'help'),
            },
          ]}
          separator
        >
          <GreetingsCard />
          <CountryRuleCard />
          <DefaultURLCard />
          <WhiteListCard />
        </Page>
      </Frame>
    )
  }
}

const mapStateToProps = (state) => {
  const { user, settings } = state;

  return {
    user: user,
    settings: settings,
  };
};

const dispatchProps = {
  getSettings,
  postSettings,
  updateData,
};

export default connect(mapStateToProps, dispatchProps)(HomeComponent);
