// @flow
//import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  FormLayout,
  TextField,
} from '@shopify/polaris';


import { updateData } from '../actions/action_settings';

//
export class GreetingsCard extends Component {

  handleRedirectTextChange = (value) => {
    const { updateData } = this.props;
    let { settings } = this.props;
    settings.data.rule.redirect_text = value;
    updateData(settings.data);
  };

  render() {
    const { settings } = this.props;
    if (!settings.data) {
      return null;
    }

    return (
      <Card
        sectioned
        title="Step 1: Greetings"
      >
        <FormLayout>
          <TextField
            label="This message will be shown in the banner."
            value={settings.data.rule.redirect_text}
            onChange={this.handleRedirectTextChange}
            multiline
          />
        </FormLayout>
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  const { settings } = state;

  return {
    settings: settings,
  };
};

const dispatchProps = {
  updateData,
};

export default connect(mapStateToProps, dispatchProps)(GreetingsCard);
