import axios from 'axios';

import type { ThunkAction } from '../types';

// Pass axios to the imported 'axios-debug' function.
//require('axios-debug')(axios);

export const updateData = (data) => {
 return ({
   type: 'UPDATE_DATA',
   payload: data
 })
}

export const getSettings = (): ThunkAction => dispatch => {
  dispatch({
    type: 'GET_SETTINGS_START',
    payload: {}
  });
  axios.defaults.headers.common['Authorization'] = `Bearer  ${sessionStorage.getItem('token')}`;
  axios
    .get(`https://${sessionStorage.getItem('engine')}/api/redirect_rule_settings`)
    .then(response => {
      //console.log(response);
      dispatch({
        type: 'GET_SETTINGS_SUCCESS',
        payload: response,
      })

    })
    .catch(error => {
      //console.log(error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: 'USER_AUTH_FAILURE',
          payload: {}
        })
      }
      dispatch({
        type: 'GET_SETTINGS_FAILURE',
        payload: error.response
      })
    })
}

export const postSettings = (data): ThunkAction => dispatch => {
  dispatch({
    type: 'POST_SETTINGS_START',
    payload: {}
  });
  //console.log('put');
  console.log(data);
  axios.defaults.headers.common['Authorization'] = `Bearer  ${sessionStorage.getItem('token')}`;
  axios
    .post(`https://${sessionStorage.getItem('engine')}/api/redirect_rule_settings`, data)
    .then(response => {
      //console.log(response);
      dispatch({
        type: 'POST_SETTINGS_SUCCESS',
        payload: response
      });
    })
    .catch(error => {
      //console.log(error.response);
      if (error.response.status === 401) {
        dispatch({
          type: 'USER_AUTH_FAILURE',
          payload: {}
        })
      }

      dispatch({
        type: 'POST_SETTINGS_FAILURE',
        payload: error.response
      })
    })
}
