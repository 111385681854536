// @flow
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { AppProvider } from '@shopify/polaris';

import store, { history } from './store';

import '@shopify/polaris/styles.css';
import './index.css';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';

axios.defaults.withCredentials = false;

const target = document.getElementById('root');

function AdapterLink({url, ...rest}) {
  return <Link to={url} {...rest} />;
}

render(
  <Provider store={store}>
    <AppProvider linkComponent={AdapterLink}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </AppProvider>
  </Provider>,
  target
);

// registerServiceWorker();
