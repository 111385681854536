// @flow
//import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  ResourceList,
  TextStyle,
  Avatar,
} from '@shopify/polaris';

import { updateData } from '../actions/action_settings';

//
export class CountryRuleCard extends Component {
  
  renderItem = (item) => {
    const {id, country, url, redirect_url } = item;
    const imgURL = `/img/${country.replace(/\W+/g, '-').toLowerCase()}.png`;
    const media = <Avatar customer size="medium" name={country} source={imgURL} />;

    return (
      <ResourceList.Item
        id={id}
        url={url}
        media={media}
        accessibilityLabel={`View details for ${country}`}
      >
        <h3>
          <TextStyle variation="strong">{country}</TextStyle>
        </h3>
        <div>{redirect_url}</div>
      </ResourceList.Item>
    );
  };

  render() {
    const { settings } = this.props;
    if (!settings.data) {
      return null;
    }

    const resourceName = {
      singular: 'Redirect rule',
      plural: 'Redirect rules',
    };

    const items = settings.data.rule.redirect_rules.map((item, id) => {
      //add the field required by ResurcesList iTem      
      return Object.assign({
        id: id,
        url: `rules/${id}`,
      }, item)
    })    

    return (
      <Card
        sectioned
        title="Step 2: Country rules"
        actions={[
          {
            content: 'Add rule',
            url: 'rules/'
          }
        ]}
      >
        <p>Add the country you would like to redirect them.</p>
        <br/>
        <Card>
          <ResourceList
            resourceName={resourceName}
            items={items}
            renderItem={this.renderItem}
            loading={false}
          />
        </Card>
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  const { settings } = state;

  return {
    settings: settings,
  };
};

const dispatchProps = {
  updateData,
};

export default connect(mapStateToProps, dispatchProps)(CountryRuleCard);