// @flow

// Reducer
const INITIAL_STATE = {
  status: null,
  isLoading: null,
  isUpdating: null,
  isUpdateSuccess: null,
  isDirty: null,
  data: null,
};

export default (state=INITIAL_STATE, action: Action): Group[] => {
  //console.log(action);
  //The response is a array, however, each shop should only have 1 settings.
  switch (action.type) {
    case 'GET_SETTINGS_START':
      return {
        ...state,
        isLoading: true,
        status: null,
        isUpdateSuccess: null,
        isDirty: null,
      };
    case 'POST_SETTINGS_START':
      return {
        ...state,
        isUpdating: true,
        status: null,
        isUpdateSuccess: null,
        isDirty: null,
      };
    case 'GET_SETTINGS_SUCCESS':
      return {
        ...state,
        data: action.payload.data,
        status: action.payload.status,
        isLoading: false,
        isDirty: null,
      };
    case 'POST_SETTINGS_SUCCESS':
      return {
        ...state,
        status: action.payload.status,
        isUpdating: false,
        isUpdateSuccess: true,
        isDirty: null,
      };
    case 'GET_SETTINGS_FAILURE':
      return {
        ...state,
        data: null,
        status: action.payload.status,
        isLoading: false,
        isDirty: null,
      };
    case 'POST_SETTINGS_FAILURE':
      return {
        ...state,
        status: action.payload.status,
        isUpdating: false,
        isUpdateSuccess: false,        
      };
    //local updateSearchText
    case 'UPDATE_DATA':
      return {
        ...state,
        data: action.payload,
        isDirty: true,
      }
    default:
      return state;
  }
};
