// @flow
//import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Page,
  FormLayout,
  TextField,
  Card,
  Frame,
  ContextualSaveBar,
  PageActions,
  Modal,
  TextContainer,
} from '@shopify/polaris';

import AutocompleteCountry from '../components/AutocompleteCountry';

import { postSettings, updateData } from '../actions/action_settings';

//
export class RuleComponent extends Component {
  constructor(props) {
    super(props);  
        
    const id = props.match.params.id;
    let country, country_code, redirect_url;
    //
    if (props.settings.data && id) {
      //Edit exisitng rule      
      const rule = props.settings.data.rule.redirect_rules[id];
      country = rule.country;
      country_code = rule.country_code;
      redirect_url = rule.redirect_url;
    }      
  
    this.state = {
      isDirty: false,
      id: id,
      country: country,
      country_code: country_code,
      redirect_url: redirect_url,
      isModalActive: false,
      isDelete: false,
      inputError: false,
    }
  }

  componentDidMount() {
    //console.log('Rules: componentDidMount');    
    //console.log(this.state);

    //if no data, redirect back to the homepage
    if (!this.props.settings.data) {
      this.props.history.push('/');
    }    
  }  

  componentDidUpdate(prevProps, prevState, snapshot) {
    //console.log('Rules: componentDidUpdate');
    const { user, settings } = this.props;
    if (user.isAuthorized === false ) {
      this.handleError();
    }
    //After successful post to server
    if (!prevProps.settings.isUpdateSuccess && settings.isUpdateSuccess) {
      this.props.history.goBack();
    }
  }
  
  isURL(str) {
    var pattern = new RegExp('^(https:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name and extension
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?'+ // port
    '(\\/[-a-z\\d%@_.~+&:]*)*'+ // path
    '(\\?[;&a-z\\d%@_.,~+&:=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return pattern.test(str);
  }

  handleLink = (path) => {
    this.props.history.push(`/${path}`);
  };

  handleTextChange = (value) => {
    //console.log(value);    
    var inputError;
    if (this.isURL(value)) {
      inputError = null;
    } else {
      inputError = "Please enter a valid URL.";
    }
    this.setState({
      isDirty: true,
      redirect_url: value,
      inputError: inputError,
    });
  }

  handleCountryChange = (code, name) => {    
    this.setState({
      isDirty: true,
      country: name,
      country_code: code,
    });
  }

  handleSave = () => {
    const { country, country_code, redirect_url, id } = this.state; 
    const { postSettings } = this.props;
    let { settings } = this.props;    
    if (id) {
      settings.data.rule.redirect_rules[id] = {
        country: country,
        country_code: country_code,
        region_code: "*",
        redirect_url: redirect_url,
      }
    } else {
      settings.data.rule.redirect_rules.push({
        country: country,
        country_code: country_code,
        region_code: "*",
        redirect_url: redirect_url,
      });
    }
    postSettings(settings.data.rule);
  }

  handleDiscard = () => {
    this.props.history.goBack();
  }

  handleChange = () => {
    this.setState(({isDelete}) => ({isDelete: !isDelete}));
  };

  handleDeleteButton = () => {
    this.setState({isDelete: true});
  }

  handleActualDelete = () => {
    const { id } = this.state; 
    const { postSettings } = this.props;
    let { settings } = this.props;
    
    if (id) {
      settings.data.rule.redirect_rules.splice(id,1);
      postSettings(settings.data.rule);    
    } else {
      //For new insert, quit directly
      this.props.history.goBack();
    }    
  }

  render() {
    //console.log('Rules: redner');
    //console.log(this.props);
    //console.log(this.state);
    const { history } = this.props;
    const { isDirty, 
      country, country_code, redirect_url,
      isDelete,
      inputError
    } = this.state;
    
    const contextualSaveBarMarkup = isDirty ? (
      <ContextualSaveBar
        message="Unsaved changes"
        saveAction={{
          onAction: this.handleSave,
        }}
        discardAction={{
          onAction: this.handleDiscard,
        }}
      />
    ) : null;
    
    const deleteModalMarkup = isDelete ? (
      <Modal
        open={isDelete}
        onClose={this.handleChange}
        title="Delete redirect rule"
        primaryAction={{
          content: 'Delete rule',
          onAction: this.handleActualDelete,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: this.handleChange,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>
            Are you sure you want to delete this rule? This action cannot be reversed.
            </p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    ) : null;

    return (
      <Frame>
        {contextualSaveBarMarkup}
        {deleteModalMarkup}
        <Page
          title="Testing Pages"
          breadcrumbs={[{content: 'Back', onAction: () => history.goBack()}]}
          secondaryActions={[
            {
              content: 'Help',
              onAction: this.handleLink.bind(this,'help'),
            },
          ]}
          separator
        >
          <Card
            sectioned
            title=""
          >
            <FormLayout>
              <AutocompleteCountry
                selected={country_code}
                selectedText={country}
                onSelected={this.handleCountryChange}
              />
              <TextField
                label="Destination URL"
                placeholder="https://"
                value={redirect_url}
                onChange={this.handleTextChange}
                error={inputError}
              />
            </FormLayout>
          </Card>
          <PageActions
            secondaryActions={[
              {
                content: 'Delete',
                destructive: true,
                onAction: this.handleDeleteButton
              }
            ]}
          />     
        </Page>
      </Frame>
    )
  }
}

const mapStateToProps = (state) => {
  const { user, settings } = state;

  return {
    user: user,
    settings: settings,
  };
};

const dispatchProps = {
  postSettings,
  updateData,
};

export default connect(mapStateToProps, dispatchProps)(RuleComponent);
