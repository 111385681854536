// @flow
import React from 'react';
import { Card, Page } from '@shopify/polaris';

const Error = ({ history }) =>
	<Page
		title="401 Unauthorized"
		secondaryActions={[
			{
				content: 'Help',
				onAction: () => history.push('/help')
			}
		]}
		separator
	>
		<Card
			title="Your session has expired."
			sectioned
		>
			<p>Please log in your Shopify Admin and launch our app again.</p>
		</Card>
	</Page>;

export default Error;
