// @flow
//import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
} from '@shopify/polaris';

import WhiteListItem from '../components/WhiteListItem';

import { updateData } from '../actions/action_settings';

//
export class WhiteListCard extends Component {

  handleAdd = () => {
    const { updateData } = this.props;
    let { settings } = this.props;
    settings.data.rule.whitelist_urls.push("")
    updateData(settings.data);
  };

  handleChange = (value, row) => {
    const { updateData } = this.props;
    let { settings } = this.props;
    settings.data.rule.whitelist_urls[row-1] = value;
    updateData(settings.data);
  };

  handleDelete = (row) => {
    const { updateData } = this.props;
    let { settings } = this.props;
    settings.data.rule.whitelist_urls.splice((row-1),1);
    updateData(settings.data);
  };

  renderItem = (item, id) => {
    const row = id + 1;
    return (
      <WhiteListItem
        key={id}
        data={item}
        id={row}
        handleWhilteListChange={this.handleChange}
        handleWhilteListDelete={this.handleDelete.bind(this, row)}
      />
    )
  }

  render() {
    const { settings } = this.props;
    if (!settings.data) {
      return null;
    }

    return (
      <Card
        sectioned
        title="Step 4: (Optional) Whitelist"
        actions={[{
          content: 'Add site',
          onAction: this.handleAdd
        }]}
      >
        <p>We will not redirect the customers if they come from these websites. Learn more. </p>
        <br/>
        {settings.data.rule.whitelist_urls.map(this.renderItem)}
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  const { settings } = state;

  return {
    settings: settings,
  };
};

const dispatchProps = {
  updateData,
};

export default connect(mapStateToProps, dispatchProps)(WhiteListCard);
